/* eslint-disable no-unused-vars */
import React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';
import linkResolver from '../utils/linkResolver';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false)
    return (
      <main>
        <h1>Not a preview!</h1>
        <p>Loading preview</p>
      </main>
    );

  return (
    <main>
      <h1>Previews Page</h1>
      <p>Loading preview</p>
    </main>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'lodestone-studio',
  linkResolver,
});
