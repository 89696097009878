/* eslint-disable no-unused-vars */
const linkResolver = ({ node, key, value }) => doc => {
  // if (doc.type === 'blog_post') {
  //   return `/blog/${doc.uid}`;
  // }

  if (doc.type === 'home_page') {
    return '/';
  }

  // Backup for all other types
  return `/${doc.uid}`;
};

module.exports = linkResolver;
